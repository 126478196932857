import React from 'react';
import { styled } from '@mui/material';
import { MenuItem as MMenuItem } from '@mui/material';

const StyledMenuItem = styled(MMenuItem)(({ theme }) => ({
  color: theme.palette.text.primary,
  border: 'none',
  '&:focus': {
    outline: 'none',
  },
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&:selected': {
    backgroundColor: 'transparent',
  },
}));

const MenuItem = ({ value, ...props }) => {
  return <StyledMenuItem style={{ border: 'none' }} {...props} value={value}></StyledMenuItem>;
};

export default MenuItem;
