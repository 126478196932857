import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './locales/en/common.json';
import lv from './locales/lv/common.json';

i18n.use(initReactI18next).use(LanguageDetector).init({
  fallbackLng: 'en',
  debug: false,
  resources: {
    en,
    lv,
  },
});

export default i18n;
