import { initializeApp } from 'firebase/app';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  doc,
  setDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyCw7sA59fPaIkbZW8vG3FZvFAKkOkQ4TCM',
  authDomain: 'spotit-9ec21.firebaseapp.com',
  projectId: 'spotit-9ec21',
  storageBucket: 'spotit-9ec21.appspot.com',
  messagingSenderId: '283157012221',
  appId: '1:283157012221:web:c157b042c32c227f8ca894',
  measurementId: 'G-4S3H0XE9XR',
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const signUpUser = async (email, password) => {
  return await createUserWithEmailAndPassword(auth, email, password);
};

const signInUser = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password);
};

const signOutUser = () => {
  return signOut(auth);
};

const fetchData = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, 'courses'));
    let data = [];
    querySnapshot.forEach((doc) => {
      const id = doc.id;
      const courseData = doc.data();
      const item = { id, ...courseData };
      data.push(item);
    });
    return data;
  } catch (error) {
    console.log('Error fetching data from Firestore', error);
    throw error;
  }
};

const createUserInFirebase = async (userId, userData) => {
  try {
    await setDoc(doc(db, 'users', userId), userData);
  } catch (error) {
    console.error('Error creating user in Firestore', error);
  }
};

export const fetchFavorites = createAsyncThunk('favorites/fetchFavorites', async (userId) => {
  const querySnapshot = await getDocs(collection(db, 'users', userId, 'favorites'));
  const favorites = querySnapshot.docs.map((doc) => doc.data());
  return favorites;
});

export const addFavorite = async (userId, courseId) => {
  try {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      favorites: arrayUnion(courseId),
    });
    return true;
  } catch (error) {
    console.error('Error adding favorite:', error);
  }
};

export const removeFavorite = async (userId, courseId) => {
  try {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      favorites: arrayRemove(courseId),
    });
    return true;
  } catch (error) {
    console.error('Error removing favorite:', error);
    return false;
  }
};

const getUserFromFirebase = async (userId) => {
  try {
    const docRef = doc(db, 'users', userId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      console.log('No user data found');
      return null;
    }
  } catch (error) {
    console.error('Error fetching user data from Firestore', error);
    return null;
  }
};

const updateUserInFirestore = async (userId, userData) => {
  try {
    await updateDoc(doc(db, 'users', userId, userData));
  } catch (error) {
    console.error('Error updating user in Firestore', error);
  }
};

export {
  db,
  app,
  getAuth,
  signUpUser,
  signInUser,
  signOutUser,
  fetchData,
  updateUserInFirestore,
  createUserInFirebase,
  getUserFromFirebase,
};
