import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Landing from './screens/Landing';
import Login from './screens/Login';
import Signup from './screens/Signup';
import Profile from 'screens/Profile';
import ErrorPage from './screens/ErrorPage';
import CoursesPage from './screens/CoursesPage';
// import PrivacyPolicy from 'screens/PrivacyPolicy';
import './index.css';
import reportWebVitals from './reportWebVitals';

import PrivateRoute from 'privateRoute';

import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

import store from './app/store';
import { Provider } from 'react-redux';

import { ThemeProvider, createTheme } from '@mui/material';
import Categories from './screens/Categories';
import './i18next';

const theme = createTheme({
  palette: {
    primary: {
      main: '#003F91',
      light: '#003F91',
      dark: '#23231A',
    },
    secondary: {
      main: '#000000',
      light: '#FFFFFF',
      dark: 'black',
      contrastText: '#979797',
    },
    common: {
      main: '#9EC8B9',
      light: '#E4751B',
    },
  },
  typography: {
    h1: {
      fontSize: 38,
      marginRight: 75,
      fontFamily: 'Roboto',
      fontWeight: 600,
      letterSpacing: 2,
    },

    h2: {
      fontSize: 32,
      color: '#F5EEE6',
      fontFamily: 'Roboto',
      fontWeight: 400,
      letterSpacing: 1,
    },

    h3: {
      fontSize: 24,
      fontFamily: 'Roboto',
      lineHeight: 1.5,
    },

    h4: {
      fontSize: 18,
      color: '#F5EEE6',
      fontFamily: 'Roboto',
      fontWeight: 600,
    },
    h5: {
      fontSize: 16,
      color: '#F5EEE6',
      fontFamily: 'Roboto',
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: '1440px',
        },
        maxWidthMd: {
          maxWidth: 320,
        },
        maxWidthLg: {
          maxWidth: '1440px!important',
        },
      },
    },
  },
});

const router = createBrowserRouter([
  {
    path: '/',
    element: <Landing />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/login',
    element: <Login />,
    errorElement: <ErrorPage />,
  },

  {
    path: '/sign-up',
    element: <Signup />,
    errorElement: <ErrorPage />,
  },

  {
    path: '/categories',
    element: <Categories />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/courses/:id',
    element: <CoursesPage />,
    errorElement: <ErrorPage />,
  },
  // {
  //   path: '/privacy-policy',
  //   element: <PrivacyPolicy />,
  //   errorElement: <ErrorPage />,
  // },
  {
    path: '/profile',
    element: (
      <PrivateRoute>
        <Profile />
      </PrivateRoute>
    ),
    errorElement: <ErrorPage />,
  },
]);

let persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <RouterProvider router={router} />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
