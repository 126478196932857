import React from 'react';
import { useState } from 'react';
import '../../../index.css';
import Checkbox from '../../checkbox';
import { Container } from '@mui/material';
import Text from '../../text';
import Box from '../../box';
import Label from '../../label';

import dropDownIcon from '../../../assets/drop-down.png';
import ratingIcon from '../../../assets/rating-1.png';
import ratingIcon1 from '../../../assets/rating-2.png';

import { useTranslation } from 'react-i18next';

const Filter = ({ onFilter }) => {
  // TODO: remove this when all checkboxes are updated
  const [selectedOptions, setSelectedOptions] = useState({});
  const { t } = useTranslation();

  const handleCheckboxChange = (event) => {
    setSelectedOptions({
      ...selectedOptions,
      [event.target.name]: event.target.checked,
    });
    onFilter(event);
  };

  return (
    <Container maxWidth="sm">
      <Box>
        <div className="filter">
          <Text text={t('filter.categories.category')} size="h3" />
          <img src={dropDownIcon} alt="Drop down icon" style={{ width: '10px', height: '10px' }} />
        </div>
      </Box>
      <Box>
        <div className="filter-options">
          <Label
            control={<Checkbox onChange={handleCheckboxChange} name="option1" />}
            label={t('filter.categories.programming')}
          />
          <Label
            control={
              <Checkbox checked={selectedOptions['option2'] || false} onChange={handleCheckboxChange} name="option2" />
            }
            label={t('filter.categories.ux_ui')}
          />
          <Label
            control={
              <Checkbox checked={selectedOptions['option3'] || false} onChange={handleCheckboxChange} name="option3" />
            }
            label={t('filter.categories.product_manegment')}
          />
          <Label
            control={
              <Checkbox checked={selectedOptions['option3'] || false} onChange={handleCheckboxChange} name="option3" />
            }
            label={t('filter.categories.qa_testing')}
          />
          <Label
            control={
              <Checkbox checked={selectedOptions['option3'] || false} onChange={handleCheckboxChange} name="option3" />
            }
            label={t('filter.categories.cybersecurity')}
          />{' '}
          <Label
            control={
              <Checkbox checked={selectedOptions['option3'] || false} onChange={handleCheckboxChange} name="option3" />
            }
            label={t('filter.categories.data_alytics')}
          />
          <Label
            control={
              <Checkbox checked={selectedOptions['option3'] || false} onChange={handleCheckboxChange} name="option3" />
            }
            label={t('filter.categories.it_devops')}
          />
          <Label
            control={
              <Checkbox checked={selectedOptions['option3'] || false} onChange={handleCheckboxChange} name="option3" />
            }
            label={t('filter.categories.business_analytics')}
          />
          <Label
            control={
              <Checkbox checked={selectedOptions['option3'] || false} onChange={handleCheckboxChange} name="option3" />
            }
            label={t('filter.categories.machine_ai')}
          />
          <Label
            control={
              <Checkbox checked={selectedOptions['option3'] || false} onChange={handleCheckboxChange} name="option3" />
            }
            label={t('filter.categories.it_general')}
          />
        </div>
      </Box>

      <Box>
        <div className="filter">
          <Text text={t('filter.certification.certification')} size="h3" />
          <img src={dropDownIcon} alt="Drop down icon" style={{ width: '10px', height: '10px' }} />
        </div>
      </Box>

      <Box marginBottom="10px">
        <div className="filter-options">
          <Label
            control={
              <Checkbox checked={selectedOptions['option2'] || false} onChange={handleCheckboxChange} name="option2" />
            }
            label={t('filter.certification.no_certificate')}
          />
          <Label
            control={
              <Checkbox checked={selectedOptions['option2'] || false} onChange={handleCheckboxChange} name="option2" />
            }
            label={t('filter.certification.with_certificate')}
          />
        </div>
      </Box>

      <Box>
        <div className="filter">
          <Text text={t('filter.level.level')} size="h3" />
          <img src={dropDownIcon} alt="Drop down icon" style={{ width: '10px', height: '10px' }} />
        </div>
      </Box>

      <Box marginBottom="10px">
        <div className="filter-options">
          <Label
            control={
              <Checkbox checked={selectedOptions['option2'] || false} onChange={handleCheckboxChange} name="option2" />
            }
            label={t('filter.level.all_levels')}
          />
          <Label
            control={
              <Checkbox checked={selectedOptions['option2'] || false} onChange={handleCheckboxChange} name="option2" />
            }
            label={t('filter.level.beginner')}
          />
          <Label
            control={
              <Checkbox checked={selectedOptions['option2'] || false} onChange={handleCheckboxChange} name="option2" />
            }
            label={t('filter.level.intermidiate')}
          />
          <Label
            control={
              <Checkbox checked={selectedOptions['option2'] || false} onChange={handleCheckboxChange} name="option2" />
            }
            label={t('filter.level.expert')}
          />
        </div>
      </Box>

      <Box>
        <div className="filter">
          <Text text={t('filter.duration.duration')} size="h3" />
          <img src={dropDownIcon} alt="Drop down icon" style={{ width: '10px', height: '10px' }} />
        </div>
      </Box>

      <Box>
        <div className="filter-options">
          <Label
            control={
              <Checkbox checked={selectedOptions['option2'] || false} onChange={handleCheckboxChange} name="option2" />
            }
            label={t('filter.duration.2_hours')}
          />
          <Label
            control={
              <Checkbox checked={selectedOptions['option2'] || false} onChange={handleCheckboxChange} name="option2" />
            }
            label={t('filter.duration.3_6_hours')}
          />
          <Label
            control={
              <Checkbox checked={selectedOptions['option2'] || false} onChange={handleCheckboxChange} name="option2" />
            }
            label={t('filter.duration.7_16_hours')}
          />
          <Label
            control={
              <Checkbox checked={selectedOptions['option2'] || false} onChange={handleCheckboxChange} name="option2" />
            }
            label={t('filter.duration.17_hours')}
          />
        </div>
      </Box>

      <Box>
        <div className="filter">
          <Text text={t('filter.format.format')} size="h3" />
          <img src={dropDownIcon} alt="Drop down icon" style={{ width: '10px', height: '10px' }} />
        </div>
      </Box>

      <Box>
        <div className="filter-options">
          <Label
            control={
              <Checkbox checked={selectedOptions['option2'] || false} onChange={handleCheckboxChange} name="option2" />
            }
            label={t('filter.format.online')}
          />
          <Label
            control={
              <Checkbox checked={selectedOptions['option2'] || false} onChange={handleCheckboxChange} name="option2" />
            }
            label={t('filter.format.in_person')}
          />{' '}
          <Label
            control={
              <Checkbox checked={selectedOptions['option2'] || false} onChange={handleCheckboxChange} name="option2" />
            }
            label={t('filter.format.hybrid')}
          />
        </div>
      </Box>

      <Box>
        <div className="filter">
          <Text text={t('filter.ratings.ratings')} size="h3" />
          <img src={dropDownIcon} alt="Drop down icon" style={{ width: '10px', height: '10px' }} />
        </div>
      </Box>
      <Box>
        <div className="filter-options">
          <Label
            control={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  checked={selectedOptions['option2'] || false}
                  onChange={handleCheckboxChange}
                  name="option2"
                />
                <div className="rating-img">
                  <img src={ratingIcon} alt="start-icon" />
                  <img src={ratingIcon} alt="start-icon" />
                  <img src={ratingIcon} alt="start-icon" />
                  <img src={ratingIcon} alt="start-icon" />
                  <img src={ratingIcon1} alt="start-icon" />
                </div>
              </div>
            }
            label={t('filter.ratings.4.5_up')}
          />
          <Label
            control={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  checked={selectedOptions['option2'] || false}
                  onChange={handleCheckboxChange}
                  name="option2"
                />
                <div className="rating-img">
                  <img src={ratingIcon} alt="start-icon" />
                  <img src={ratingIcon} alt="start-icon" />
                  <img src={ratingIcon} alt="start-icon" />
                  <img src={ratingIcon} alt="start-icon" />
                  <img src={ratingIcon1} alt="start-icon" />
                </div>
              </div>
            }
            label={t('filter.ratings.4_up')}
          />
          <Label
            control={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  checked={selectedOptions['option2'] || false}
                  onChange={handleCheckboxChange}
                  name="option2"
                />
                <div className="rating-img">
                  <img src={ratingIcon} alt="start-icon" />
                  <img src={ratingIcon} alt="start-icon" />
                  <img src={ratingIcon} alt="start-icon" />
                  <img src={ratingIcon} alt="start-icon" />
                  <img src={ratingIcon1} alt="start-icon" />
                </div>
              </div>
            }
            label={t('filter.ratings.3_up')}
          />
        </div>
      </Box>
      <Box>
        <div className="filter">
          <Text text={t('filter.language.language')} size="h3" />
          <img src={dropDownIcon} alt="Drop down icon" style={{ width: '10px', height: '10px' }} />
        </div>
      </Box>

      <Box>
        <div className="filter-options">
          <Label
            control={
              <Checkbox checked={selectedOptions['option2'] || false} onChange={handleCheckboxChange} name="option2" />
            }
            label={t('filter.language.english')}
          />
          <Label
            control={
              <Checkbox checked={selectedOptions['option2'] || false} onChange={handleCheckboxChange} name="option2" />
            }
            label={t('filter.language.latvian')}
          />{' '}
        </div>
      </Box>
    </Container>
  );
};

export default Filter;
