import React from 'react';
import { styled } from '@mui/material';
import { Checkbox as MCheckbox } from '@mui/material';

const StyledCheckbox = styled(MCheckbox)(({ theme }) => ({
  color: theme.palette.secondary.main,
  paddingLeft: '0',

  '&.Mui-checked': {
    color: theme.palette.secondary.main,
  },
}));

const Checkbox = ({ text, onChange }) => {
  const handleChange = (event) => {
    onChange(event);
  };

  return (
    <StyledCheckbox onChange={handleChange} inputProps={{ 'aria-label': 'Remember me checkbox' }}>
      {text}
    </StyledCheckbox>
  );
};

export default Checkbox;
