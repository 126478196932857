import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import DehazeRoundedIcon from '@mui/icons-material/DehazeRounded';
import { AppBar, Box, IconButton, useMediaQuery, Drawer, List, ListItemText, ListItem, Popover } from '@mui/material';
import { styled } from '@mui/material';
import { Toolbar as MToolbar } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Text from '../text';
import Select from '../select';
import MenuItem from '../menu-item';
// import bookmarkSmallImage from '../../assets/bookmark-small.png';
// import alarmImage from '../../assets/alarm.png';
import logoIcon from '../../assets/spotit-logo.png';
import accountIcon from '../../assets/account.png';
import Input from '../input';
import searchIcon from '../../assets/search.png';
import globeIcon from '../../assets/globe.png';
import { logoutUser } from '../../features/user/userSlice';
import { signOutUser } from '../../firebase';
import { useTranslation } from 'react-i18next';

const StyledToolbar = styled(MToolbar)(({ theme }) => ({
  color: theme.palette,
  backgroundColor: 'transparent',

  fontCase: 'lowercase',
  '& *': {
    color: '#000000',
  },
  '&.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular': {
    justifyContent: 'space-between',
    paddingLeft: '10%',
    paddingRight: '10%',
    paddingTop: '20px',
    paddingBottom: '20px',
    boxShadow: '0 4px 6px 1px rgba(0, 0, 0, 0.1), 0 3px 4px 1px rgba(0, 0, 0, 0.06)',
  },
}));

const Navbar = ({ children, size }) => {
  return <StyledToolbar variant={size}>{children}</StyledToolbar>;
};

const LoggedInAppBar = () => {
  const { data } = useSelector((state) => state.data);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [language, setLanguage] = React.useState(i18n.language);
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  //   const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const [searchField, setSearchField] = useState('');

  const filteredCourses =
    data &&
    data.filter((course) => {
      return course.title.toLowerCase().includes(searchField.toLowerCase());
    });

  const handleChange = (e) => {
    setSearchField(e.target.value);
  };

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;

    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogOut = () => {
    signOutUser()
      .then(() => {
        dispatch(logoutUser());
        navigate('/');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const drawer = (
    <div>
      <List>
        <ListItem button>
          <ListItemText primary="Home" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <AppBar position="fixed" style={{ background: '#fff', boxShadow: 'none', width: '100%', zIndex: 999 }}>
      <Navbar sx={{ justifyContent: 'space-between' }}>
        {isMobile ? (
          <>
            <IconButton edge="start" color="inherit" arial-label="menu" onClick={handleDrawerToggle}>
              <DehazeRoundedIcon />
            </IconButton>
            <IconButton edge="sart" color="inherit" arial-label="logo">
              <img src={logoIcon} alt="spotit-logo" style={{ width: '100%' }} />
            </IconButton>
            <IconButton edge="sart" color="inherit" arial-label="logo">
              <img src={accountIcon} alt="account-icon" style={{ width: '100%' }} />
            </IconButton>
            <Drawer anchor="left" open={mobileOpen} onClose={handleDrawerToggle}>
              {drawer}
            </Drawer>
          </>
        ) : (
          <div className="nav-items">
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '100px' }}>
              <a href="/">
                <img src={logoIcon} alt="spotit-logo" width={107} height={50} />
              </a>
              <a href="/#courses-list" className="global-a-hovers">
                <Text text={t('nav.explore_courses')} size="h5" sx={{ flexGrow: 1 }}></Text>
              </a>
              <a
                href="https://docs.google.com/forms/d/1fTNTerYiQgumiI38AK3jQ5KRhhYrAVNBpGtUJZONx_E"
                target="_blank"
                rel="noreferrer"
                className="global-a-hovers"
              >
                <Text text={t('nav.submit_courses')} size="h5" sx={{ flexGrow: 1 }}></Text>
              </a>
              <div>
                <Input
                  placeholder={t('nav.search')}
                  size="small"
                  icon={searchIcon}
                  style={{ width: '352px' }}
                  onChange={handleChange}
                />
                {searchField !== '' && filteredCourses && filteredCourses.length > 0 && (
                  <div className="search-results">
                    {filteredCourses.map((course) => {
                      return (
                        <a href={`/courses/${course.id}`} key={course.id}>
                          <Text text={course.title} size="h5" marginBottom={8} />
                        </a>
                      );
                    })}
                  </div>
                )}
              </div>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '45px' }}>
                {/* Not needed for now, but can be used later */}
                {/* <img src={bookmarkSmallImage} alt="bookmark" width="15px" />
                <img src={alarmImage} alt="alarm" width="18px" /> */}
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '0' }}>
                  <img src={globeIcon} alt="globe" style={{ width: '17px' }} />
                  <Select value={language} onChange={handleLanguageChange} sx={{ mr: 2 }}>
                    <MenuItem value="lv">LV</MenuItem>
                    <MenuItem value="en">ENG</MenuItem>
                  </Select>
                </Box>
                <Stack>
                  <Avatar
                    aria-describedby={id}
                    onClick={handleClick}
                    sx={{
                      bgcolor: '#FFFFFF',
                      border: '2px solid #E4751B',
                      color: '#000000',
                      width: '42px',
                      cursor: 'pointer',
                    }}
                  >
                    A
                  </Avatar>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    <Box sx={{ padding: '16px', gap: '10px', display: 'grid' }}>
                      <a href="/profile" style={{ marginBottom: '10px' }}>
                        <Text text={t('nav.profile')} size="h5"></Text>
                      </a>
                      <a href="#" onClick={handleLogOut}>
                        <Text text={t('nav.log_out')} size="h5"></Text>
                      </a>
                    </Box>
                  </Popover>
                </Stack>
              </Box>
            </Box>
          </div>
        )}

        {/* {isLargeScreen && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '45px' }}>
              <img src={bookmarkSmallImage} alt="bookmark" width="15px" />
              <img src={alarmImage} alt="alarm" width="18px" />
              <Stack>
                <Avatar sx={{ bgcolor: '#FFFFFF', border: '2px solid #E4751B', color: '#000000', width: '42px' }}>
                  A
                </Avatar>
              </Stack>
            </Box>
          </Box>
        )} */}
      </Navbar>
    </AppBar>
  );
};

export default LoggedInAppBar;
