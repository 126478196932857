import React from 'react';
import { Grid } from '@mui/material';
import CourseCard from '../course-card';
import SideMenu from '../side-menu';
import Card from '../../card';
import '../../../index.css';

const CoursesList = ({ courses, id }) => {
  return (
    <Grid id={id}>
      <ul style={{ listStyleType: 'none', marginTop: '0' }}>
        {courses.map((course) => (
          <Grid key={course.id}>
            <Card>
              <Grid container spacing={2}>
                <Grid item xs={12} md={9}>
                  <CourseCard key={course.id} course={course} />
                </Grid>
                <Grid item xs={12} md={3} className="side-menu-container">
                  <SideMenu course={course} />
                </Grid>
              </Grid>
            </Card>
          </Grid>
        ))}
      </ul>
    </Grid>
  );
};

export default CoursesList;
