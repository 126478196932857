import React from 'react';
import { Box } from '@mui/material';
import Text from 'components/text';
import Link from 'components/link';
import '../../index.css';
import { useNavigate } from 'react-router-dom';
import Button from 'components/button';

const FavoriteCard = ({ course }) => {
  const { title, description, instructor, lastUpdated, courseImageUrl } = course;

  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate('/courses/' + course.id);
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: 'auto',
        minHeight: '600px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
        position: 'relative',
        paddingRight: 0,
      }}
    >
      <Box
        sx={{
          backgroundImage: `url(${courseImageUrl})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '155px',
          width: '100%',
          borderRadius: '10px 10px 0 0',
          marginTop: '20px',
          paddingRight: '0',
        }}
      ></Box>
      <div className="favorite-card-container">
        <Text marginBottom="10px" lineHeight="1.5" size="h4" text={title} />
        <Link customColor="primary.light">
          <div style={{ marginBottom: '15px' }}>{instructor}</div>
        </Link>
        <Text marginBottom="15px" size="h5" customColor="secondary.contrastText" text={lastUpdated} />
        <Text marginBottom="15px" text={description.length > 140 ? description.slice(0, 147) + '...' : description} />
        <div className="favourite-button" style={{ position: 'absolute', bottom: 0 }}>
          <Button text="More info" textTransform="lowercase" onClick={handleCardClick} />
        </div>
      </div>
    </Box>
  );
};

export default FavoriteCard;
