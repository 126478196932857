import React from 'react';
import { styled, useTheme, useMediaQuery } from '@mui/material';
import { Typography as MTypography } from '@mui/material';

const StyledTypography = styled(MTypography)(({ theme }) => ({
  color: theme.palette.secondary.main,

  '&.Mui-checked': {
    color: theme.palette.secondary.main,
  },
}));

const HighlightedText = styled('span')({
  color: '#E4751B',
});

const Title = ({ text = '', highlightedText = '', sx }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const variant = isSmallScreen ? 'h4' : 'h3';

  const textString = String(text);

  const textParts = highlightedText ? textString.split(highlightedText) : [textString];

  return (
    <StyledTypography variant={variant} sx={sx}>
      {textParts[0]}
      <HighlightedText>
        {highlightedText}
        {textParts[1]}
      </HighlightedText>
    </StyledTypography>
  );
};

export default Title;
