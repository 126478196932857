import React from 'react';
import { styled } from '@mui/system';
import { TextField as NewTextField } from '@mui/material';
import { ExpandMenuButton } from '../../categories-button';
import { useTranslation } from 'react-i18next';

const StyledInput = styled(NewTextField)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  width: '485px',
  height: '75px',
  borderRadius: '10px',

  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderWidth: 0,
    },

    '&.Mui-focused fieldset': {
      borderWidth: 0,
    },
    '& input': {
      height: '42px',
      width: '100%',
    },
  },
}));

const SubInput = ({ size, text, children, onClick, ...props }) => {
  const { t } = useTranslation();

  return (
    <StyledInput
      {...props}
      text={text}
      size={size}
      InputProps={{
        style: {
          color: '#979797',
          display: 'flex',
          justifyContent: 'center',
          paddingRight: '0',
        },
        endAdornment: (
          <ExpandMenuButton
            variant="contained"
            color="primary"
            text={t('footer.subscribe')}
            noLeftRadius={true}
            width={false}
            height={75}
            onClick={onClick}
          ></ExpandMenuButton>
        ),
      }}
    >
      {children}
    </StyledInput>
  );
};

export default SubInput;
