import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import '../index.css';

import Page from '../components/page';
import Categories from './Categories';
import CoursesList from '../components/landing-components/courses-list';
import Filter from '../components/landing-components/filter';
import Text from '../components/text';
import { ExpandMenuButton } from 'components/categories-button';

import filterIcon from '../assets/filter-1.png';

import { fetchData } from '../firebase';
import { useSelector, useDispatch } from 'react-redux';
import { setData } from '../features/data/dataSlice';
import { useTranslation } from 'react-i18next';

const Landing = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.data);
  const { t } = useTranslation();

  const [visibleCourses, setVisibleCourses] = useState(4);

  const loadMoreCourses = () => {
    setVisibleCourses(visibleCourses + 4);
  };

  useEffect(() => {
    fetchData()
      .then((fetchedData) => {
        dispatch(setData(fetchedData));
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dispatch]);

  useEffect(() => {
    const scrollToElement = () => {
      if (location.hash) {
        const element = document.getElementById(location.hash.substring(1));
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    };

    if (data && data.length > 0) {
      const timeoutId = setTimeout(scrollToElement, 200);
      return () => clearTimeout(timeoutId);
    } else {
      const timeoutId = setTimeout(scrollToElement, 200);
      return () => clearTimeout(timeoutId);
    }
  }, [data]);

  const onFilter = (event) => {
    console.log(event);
  };

  return (
    <Page showHero>
      <section id="courses-list">
        <Grid container style={{ paddingLeft: '24px', marginBottom: '40px' }}>
          <Text text={t('filter.filter_explorer')} size="h3" />
        </Grid>

        <Grid>
          <div
            style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '16px', paddingLeft: '24px' }}
          >
            <img src={filterIcon} alt="Filter Icon" style={{ width: '17px', height: '17px' }} />
            <Text text={t('filter.filter_by')} size="h4" style={{ marginLeft: '8px' }} />
          </div>
        </Grid>

        <Grid container>
          <Grid item xs={12} sm={4} style={{ display: 'Flex' }} overflowWrap="break-word">
            <Filter onFilter={onFilter} />
          </Grid>
          <Grid item xs={12} sm={8}>
            <CoursesList courses={data.slice(0, visibleCourses)} />
          </Grid>
        </Grid>
        <div className="load-more-button">
          <ExpandMenuButton
            text={t('buttons.load_more')}
            onClick={loadMoreCourses}
            textTransformProp="initial"
            fullWidth={false}
          />
        </div>
        <Categories />
      </section>
    </Page>
  );
};

export default Landing;
