import React from 'react';
import Text from '../../text';
import { Grid } from '@mui/material';

import benefitsImage from '../../../assets/location.png';

const CourseBenefits = ({ benefits }) => {
  if (typeof benefits !== 'object' || benefits === null) {
    return <div>Invalid benefits data</div>;
  }

  const benefitsArray = Object.entries(benefits).map(([key, value]) => ({ id: key, text: value.text }));

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {benefitsArray.slice(0, 2).map((benefit) => {
            return (
              <div className="course-benefits" key={benefit.id}>
                <img src={benefitsImage} alt="" width="24px" height="24px" />
                <Text text={benefit.text || ''} size="h5" lineHeight="25px" />
              </div>
            );
          })}
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {benefitsArray.slice(2, 4).map((benefit) => {
            return (
              <div className="course-benefits" key={benefit.id}>
                <img src={benefitsImage} alt="" width="24px" height="24px" />
                <Text size="h5" lineHeight="25px" text={benefit.text || ''} />
              </div>
            );
          })}
        </div>
      </Grid>
    </Grid>
  );
};

export default CourseBenefits;
