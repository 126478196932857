import React, { useState } from 'react';
import { Grid, Container, Box } from '@mui/material';
import '../components/styles.css';
import { ExpandMenuButton } from '../components/categories-button';
import { useForm, Controller } from 'react-hook-form';
import { getUserFromFirebase, signInUser } from '../firebase';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setUser } from '../features/user/userSlice';
import { useTranslation } from 'react-i18next';

import bookmarkIcon from 'assets/bookmark-1.png';
import boxIcon from 'assets/box.png';
import continueIcon from 'assets/continue.png';

import Link from '../components/link';
import Text from '../components/text';
import Input from '../components/input';
import PasswordInput from '../components/passwordInput';

import Page from 'components/page';

import loginImg from 'assets/login-signup-min.png';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit = async (data) => {
    try {
      const userCredentials = await signInUser(data.email, data.password);
      const firebaseUser = userCredentials.user;
      const user = await getUserFromFirebase(firebaseUser.uid);

      if (user) {
        dispatch(setUser(user));
        navigate('/profile');
      } else {
        console.log('No user found');
      }
    } catch (error) {
      var errorCode = error.code;
      setError(errorCode);
    }
  };

  return (
    <Page>
      <div className="login-container">
        <Container>
          <Box sx={{ boxShadow: 3 }}>
            <Grid container spacing={2} sx={{ marginLeft: '0' }}>
              <Grid items xs={12} md={6}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '7px',
                    marginTop: '150px',
                  }}
                >
                  <Text text={t('login.log_in')} fontSize="28px" fontWeight="500" />
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', padding: '0px 84px' }}>
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <Controller
                      name="email"
                      control={control}
                      rules={{
                        required: t('login.email_required'),
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: (
                            <Text text={t('login.email_invalid')} style={{ marginTop: '5px', color: '#cc0a27' }} />
                          ),
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <Input
                            value={value}
                            onChange={onChange}
                            placeholder={t('login.email_placeholder')}
                            type="email"
                            size="medium"
                            name="email"
                            background="#EBEBEB"
                            height="75px"
                            marginBottom="16px"
                            style={{ marginTop: '92px' }}
                          />
                          {errors.email && (
                            <p style={{ marginTop: '-15px', color: '#cc0a27', fontFamily: 'Roboto' }}>
                              {errors.email.message}
                            </p>
                          )}
                        </>
                      )}
                    />
                    <div className="forgot-password">
                      <Text text={t('login.forgot_password')} size="h5" customColor="primary.light" />
                    </div>

                    <Controller
                      name="password"
                      control={control}
                      rules={{
                        required: t('login.password_required'),
                        minLength: {
                          value: 8,
                          message: (
                            <Text text={t('login.password_invalid')} style={{ marginTop: '5px', color: '#cc0a27' }} />
                          ),
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <PasswordInput
                            value={value}
                            onChange={onChange}
                            placeholder={t('login.password_placeholder')}
                            type="password"
                            size="medium"
                            name="password"
                            background="#EBEBEB"
                            height="75px"
                            marginBottom="20px"
                            style={{ marginTop: '6px' }}
                          />
                          {errors.password && (
                            <p style={{ marginTop: '-15px', color: '#cc0a27', marginBottom: '24px', fontFamily: '' }}>
                              {errors.password.message}
                            </p>
                          )}
                        </>
                      )}
                    />
                    <div style={{ marginTop: 24 }} />
                    <ExpandMenuButton text={t('buttons.login')} fullWidth height={55} type="submit" fontSize="20px" />
                    {error !== '' && (
                      <Text text={t('login.error')} style={{ color: '#cc0a27', marginTop: '10px' }}></Text>
                    )}
                  </form>

                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
                    <Text text={t('login.dont_have_account')} size="h5" marginRight="5px" />
                    <Link text={t('login.sign_up')} size="h5" customColor="common.light" to="/sign-up" />
                  </div>
                </div>
              </Grid>
              <Grid items xs={12} md={6}>
                <img
                  src={loginImg}
                  alt=""
                  style={{
                    maxWidth: '100%',

                    height: 'auto',
                    display: 'block',
                    verticalAlign: 'middle',
                  }}
                />
                <div className="benefits-container">
                  <Text text={t('common.spotit_platform')} highlightedText="it" size="h3" />
                  <div className="benefits-section">
                    <img src={bookmarkIcon} alt="" style={{ width: '34px', height: '34px', marginRight: '20px' }} />
                    <Text text={t('login.benefits.benefit_1.text')} size="h3" />
                  </div>
                  <div>
                    <Text text={t('login.benefits.benefit_1.sub_text')} size="h5" marginLeft="55px" lineHeight="30px" />
                  </div>
                  <div className="benefits-section">
                    <img src={boxIcon} alt="" style={{ width: '34px', height: '34px', marginRight: '20px' }} />
                    <Text text={t('login.benefits.benefit_2.text')} size="h3" />
                  </div>
                  <div>
                    <Text text={t('login.benefits.benefit_2.sub_text')} size="h5" marginLeft="55px" lineHeight="30px" />
                  </div>
                  <div className="benefits-section">
                    <img
                      src={continueIcon}
                      alt=""
                      style={{ width: '25px', height: '25px', marginLeft: '6px', marginRight: '20px' }}
                    />
                    <Text text={t('login.benefits.benefit_3.text')} size="h3" marginLeft="3px" />
                  </div>
                  <div>
                    <Text text={t('login.benefits.benefit_3.sub_text')} size="h5" marginLeft="55px" lineHeight="30px" />
                  </div>
                </div>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </div>
    </Page>
  );
};

export default Login;
