import React from 'react';
import { CategoriesButton } from '../components/categories-button';
import { useTranslation } from 'react-i18next';

import '../index.css';
import { useSelector } from 'react-redux';

import { Grid, Box } from '@mui/material';
import Title from 'components/typography';

import FavoriteCard from 'components/favorite-card';

import bookmarkImage from '../assets/favourite-icon.png';

import Page from 'components/page';

const Profile = () => {
  const { user } = useSelector((state) => state.user);
  const { data } = useSelector((state) => state.data);

  const favoritesIDs = user?.favorites || [];
  const favorites = data.filter((course) => favoritesIDs.includes(course.id));
  const { t } = useTranslation();

  return (
    <Page>
      <Grid>
        <div className="wishlist-block">
          <div className="wishlist-block--background-div"></div>
          <div className="my-wishlist">
            <img
              className="bookmark-wishlist-img"
              src={bookmarkImage}
              style={{ width: '20px', height: '25px' }}
              alt="bookmark"
            />
            <Title text="My Wishlist" variant="h3" />
            <Title
              sx={{ marginLeft: '20px' }}
              text={(favorites && favorites.length) || '0'}
              variant="h5"
              style={{ marginLeft: '10px' }}
            />
          </div>
        </div>
      </Grid>
      <Grid>
        {favorites && favorites.length > 0 ? (
          <div>
            <Box>
              <Grid container spacing={2}>
                {favorites.map((course) => (
                  <Grid item key={course.id} xs={12} sm={6} md={3}>
                    <Box>
                      <FavoriteCard course={course} />
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </div>
        ) : (
          <Grid item xs={12} md={12}>
            <div className="explore-button-container">
              <CategoriesButton text={t('buttons.explore_courses')} href={'/#courses-list'} />
            </div>
          </Grid>
        )}
      </Grid>
    </Page>
  );
};

export default Profile;
