import React from 'react';
import Text from '../../text';
import { Grid, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ExpandMenuButton } from 'components/categories-button';
import { useTranslation } from 'react-i18next';

const CourseCard = ({ course }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleCardClick = () => {
    navigate('/courses/' + course.id);
  };

  return (
    <Grid>
      <li key={course.id}>
        <Grid container>
          <Grid item xs={12} sm={4}>
            <Box
              sx={{
                backgroundImage: `url(${course.cardImageUrl})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '472px',
                width: '201px',
                borderRadius: '10px 0 0 10px',
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            style={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              marginTop: '40px',
              paddingLeft: '20px',
              paddingRight: '20px',
            }}
          >
            <Text text={course.title} size="h4" marginBottom="10px" lineHeight="30px" />
            <Text text={`${course.instructor}`} size="body1" marginBottom="10px" customColor="primary.light" />
            <Text text={`${course.lastUpdated}`} size="caption" marginBottom="30px" />
            <Text
              text={`${
                course.description.length > 200 ? course.description.slice(0, 147) + '...' : course.description
              }`}
              size="body1"
              overflowWrap="break-word"
              lineHeight="30px"
            />

            <Grid
              item
              xs={12}
              style={{
                marginTop: 'auto',
                marginBottom: '40px',
                display: 'flex',
                alignItems: 'end',
                justifyContent: 'center',
              }}
            >
              <ExpandMenuButton
                text={t('buttons.more_info')}
                fontSize="16px"
                onClick={handleCardClick}
                fullWidth={false}
              />

              {/* <Rating />
                  <Text text="324 Reviews" marginLeft="10px" /> */}
            </Grid>
          </Grid>
        </Grid>
      </li>
    </Grid>
  );
};

export default CourseCard;
