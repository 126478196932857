import { useSelector } from 'react-redux';
import React from 'react';
import { Container } from '@mui/material';
import Footer from 'components/landing-components/footer';
import MyAppBar from 'components/navbar';
import LoggedInAppBar from 'components/logged-in-app-bar';
import heroImage from 'assets/hero-section-min.png';
import Text from 'components/text';
import { useTranslation } from 'react-i18next';

const Page = ({ children, showHero = false }) => {
  const { user } = useSelector((state) => state.user);
  const { t } = useTranslation();
  return (
    <>
      {user !== null ? <LoggedInAppBar /> : <MyAppBar />}
      {showHero ? (
        <div className="hero-block">
          <div className="hero-img">
            <img src={heroImage} alt="Description" style={{ width: '100%', height: 'auto' }} />
          </div>
          <div className="hero-text-container">
            <div className="title">
              <Text
                text={t('common.discover_and_learn')}
                fontSize="48px"
                fontWeight="500"
                highlightedText="it"
                customColor="secondary.light"
              />
            </div>
            <div className="hero-subtitle">
              <Text
                text={t('hero.hero_text')}
                fontSize="24px"
                sx={{ padding: '0' }}
                customColor="secondary.light"
                fontWeight="400"
                lineHeight="40px"
              />
            </div>
          </div>
        </div>
      ) : null}
      <Container sx={{ paddingTop: '120px' }}>{children}</Container>
      <Footer />
    </>
  );
};

export default Page;
