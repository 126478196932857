import React, { useState } from 'react';
import { Grid, Container, Box } from '@mui/material';
import '../components/styles.css';
import { useForm, Controller } from 'react-hook-form';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setUser } from '../features/user/userSlice';
import { signUpUser } from '../firebase';
import { createUserInFirebase } from '../firebase';
import { useTranslation } from 'react-i18next';

import bookmarkIcon from 'assets/bookmark-1.png';
import boxIcon from 'assets/box.png';
import continueIcon from 'assets/continue.png';

import Link from '../components/link';
import Text from '../components/text';
import Input from '../components/input';
import PasswordInput from '../components/passwordInput';

import Page from 'components/page';
import { ExpandMenuButton } from '../components/categories-button';

import signupImg from 'assets/login-signup-min.png';

const Signup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit = async (data) => {
    signUpUser(data.email, data.password)
      .then(async (userCredentials) => {
        const firebaseUser = userCredentials.user;
        const user = {
          id: firebaseUser.uid,
          email: data.email,
          favorites: [],
        };
        try {
          await createUserInFirebase(firebaseUser.uid, user);
        } catch (error) {
          console.error('error creating user in Firestore', error);
        }
        dispatch(setUser(user));
        navigate('/profile');
      })
      .catch((error) => {
        var errorCode = error.code;
        setError(errorCode);
      });
  };

  return (
    <Page>
      <div className="signup-container">
        <Container>
          <Box sx={{ boxShadow: 3 }}>
            <Grid container spacing={2} sx={{ marginLeft: '0' }}>
              <Grid items xs={12} md={6}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '7px',
                    marginTop: '150px',
                  }}
                >
                  <Text text={t('sign_up.set_up')} fontSize="28px" fontWeight="500" />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Text text={t('sign_up.already_have')} size="h5" marginRight="5px" />
                  <Link text={t('sign_up.login')} size="h5" customColor="common.light" to="/login" />
                </div>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  style={{ display: 'flex', flexDirection: 'column', padding: '0px 84px' }}
                >
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: t('sign_up.email_required'),
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: <Text text={t('sign_up.email_invalid')} style={{ color: '#cc0a27' }} />,
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <>
                        <Input
                          value={value}
                          onChange={onChange}
                          placeholder={t('sign_up.email_placeholder')}
                          type="email"
                          size="medium"
                          name="email"
                          background="#EBEBEB"
                          height="75px"
                          style={{ marginTop: '70px' }}
                          marginBottom="44px"
                        />
                        {errors.email && (
                          <p style={{ marginTop: '-39px', color: '#cc0a27', fontFamily: 'Roboto' }}>
                            {errors.email.message}
                          </p>
                        )}
                      </>
                    )}
                  />
                  <Controller
                    name="password"
                    control={control}
                    rules={{
                      required: t('sign_up.password_required'),
                      minLength: {
                        value: 8,
                        message: (
                          <Text text={t('sign_up.password_invalid')} style={{ marginTop: '10px', color: '#cc0a27' }} />
                        ),
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <>
                        <PasswordInput
                          value={value}
                          onChange={onChange}
                          placeholder={t('sign_up.password_placeholder')}
                          type="paswword"
                          size="medium"
                          name="password"
                          background="#EBEBEB"
                          height="75px"
                          marginBottom="20px"
                        />
                        {errors.password && (
                          <p style={{ marginTop: '-15px', color: '#cc0a27', marginBottom: '24px' }}>
                            {errors.password.message}
                          </p>
                        )}
                      </>
                    )}
                  />
                  <div style={{ marginTop: 24 }} />
                  <ExpandMenuButton text={t('buttons.sign_up')} fullWidth height={55} type="submit" fontSize="20px" />
                  {error !== '' && <p style={{ color: '#cc0a27' }}>Some error happened, please try again</p>}
                </form>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
                  <Text text={t('sign_up.accept')} size="h5" marginRight="5px" />
                  <Link text={t('sign_up.terms_conditions')} size="h5" customColor="common.light" />
                </div>
              </Grid>
              <Grid items xs={12} md={6}>
                <img
                  src={signupImg}
                  alt=""
                  style={{
                    width: '100%',
                    maxWidth: '710px',
                    height: 'auto',
                    display: 'block',
                    verticalAlign: 'middle',
                  }}
                />
                <div className="benefits-container">
                  <Text text={t('common.spotit_platform')} highlightedText="it" size="h3" />
                  <div className="benefits-section">
                    <img src={bookmarkIcon} alt="" style={{ width: '34px', height: '34px', marginRight: '20px' }} />
                    <Text text={t('sign_up.benefits.benefit_1.text')} size="h3" />
                  </div>
                  <div>
                    <Text
                      text={t('sign_up.benefits.benefit_1.sub_text')}
                      size="h5"
                      marginLeft="55px"
                      lineHeight="30px"
                    />
                  </div>
                  <div className="benefits-section">
                    <img src={boxIcon} alt="" style={{ width: '34px', height: '34px', marginRight: '20px' }} />
                    <Text text={t('sign_up.benefits.benefit_2.text')} size="h3" />
                  </div>
                  <div>
                    <Text
                      text={t('sign_up.benefits.benefit_2.sub_text')}
                      size="h5"
                      marginLeft="55px"
                      lineHeight="30px"
                    />
                  </div>
                  <div className="benefits-section">
                    <img
                      src={continueIcon}
                      alt=""
                      style={{ width: '25px', height: '25px', marginLeft: '6px', marginRight: '20px' }}
                    />
                    <Text text={t('sign_up.benefits.benefit_3.text')} size="h3" marginLeft="3px" />
                  </div>
                  <div>
                    <Text
                      text={t('sign_up.benefits.benefit_3.sub_text')}
                      size="h5"
                      marginLeft="55px"
                      lineHeight="30px"
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </div>
    </Page>
  );
};

export default Signup;
