import React from 'react';
import { InputAdornment, styled } from '@mui/material';
import { TextField as MInput } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const StyledInput = styled(MInput)(({ theme, background, height, marginBottom }) => ({
  '& .MuiInputLabel-root': {
    color: theme.palette.secondary,
  },
  '& .MuiInputBase-root': {
    borderRadius: '10px',
    backgroundColor: background || 'transparent',
    height: height || 'auto',
    marginBottom: marginBottom || '0',
    outline: 'none',
    '&::before': {
      borderBottom: '40px',
    },
  },
  '& .MuiInputAdornment-root img': {
    width: 20,
    height: 20,
  },
}));

const PasswordInput = ({ size, height, background, marginBottom, ...props }) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <StyledInput
      {...props}
      type={showPassword ? 'text' : 'password'}
      size={size}
      height={height}
      background={background}
      marginBottom={marginBottom}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordInput;
