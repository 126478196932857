import React from 'react';
import { styled } from '@mui/system';
import { Button as MButton } from '@mui/material';

const StyledButton = styled(MButton)(({ small, textTransformProp, marginTop, bordersNo, height }) => ({
  color: '#003F91',
  borderRadius: small ? 10 : 8,
  borderTopLeftRadius: bordersNo ? 0 : 10,
  borderBottomLeftRadius: bordersNo ? 0 : 10,
  marginTop: marginTop,
  // fontSize: small ? 14 : 16,
  fontSize: '16px',
  fontWeight: '500',
  width: '140px',
  height: height || '53px',
  boxShadow: 'none',
  // marginBottom: '20px',
  backgroundColor: '#ffffff',
  border: '2px solid #003F91',
  textTransform: textTransformProp === 'lowercase' ? 'lowercase' : 'initial',

  '&:hover': {
    border: '2px solid #E4751B',
    backgroundColor: '#E4751B',
    color: '#000000',
    boxShadow: 'none',
  },
}));

const Button = ({
  text,
  onClick,
  textColor,
  small = false,
  textTransformProp = 'initial',
  marginTop,
  bordersNo,
  href,
  children,
  target,
  rel,
  height,
  fontWeight,
}) => {
  return (
    <StyledButton
      variant="contained"
      onClick={onClick}
      textColor={textColor}
      small={small}
      textTransformProp={textTransformProp}
      sx={{ color: '#003F91' }}
      marginTop={marginTop}
      bordersNo={bordersNo}
      href={href}
      target={target}
      rel={rel}
      height={height}
      fontWeight={fontWeight}
    >
      {text}
      {children}
    </StyledButton>
  );
};

const StyledSignupButton = styled(MButton)(({ theme, textTransformProp }) => ({
  backgroundColor: theme.palette.common.light,
  border: '2px solid #E4751B',
  color: theme.palette.secondary.dark,
  borderRadius: '10px',
  fontSize: '16px',
  fontWeight: '500',
  width: '140px',
  boxShadow: 'none',
  textTransform: textTransformProp === 'lowercase' ? 'lowercase' : 'initial',

  '&:hover': {
    border: '2px solid #E4751B',
    backgroundColor: '#ffffff',
    boxShadow: 'none',
  },
}));

export const SignupButton = ({ text, children, textTransformProp = 'initial', href, target, rel }) => {
  return (
    <StyledSignupButton variant="contained" textTransformProp={textTransformProp} href={href} target={target} rel={rel}>
      {children}
      {text}
    </StyledSignupButton>
  );
};

export default Button;
