import React from 'react';
import { styled } from '@mui/material';
import { Link as MLink } from 'react-router-dom';

const StyledLink = styled(MLink)(({ theme, large, fontCase, customColor, lineHeight, marginBottom }) => {
  const [colorKey, shade] = customColor ? customColor.split('.') : ['secondary', 'main'];

  const isValidKey = theme.palette[colorKey];

  const color = isValidKey ? theme.palette[colorKey][shade || 'main'] : theme.palette.secondary.main;

  return {
    color,
    textDecoration: 'none',
    fontSize: large ? 20 : 16,
    fontFamily: 'Roboto',
    textTransform: fontCase === 'uppercase' ? 'uppercase' : 'initial',
    lineHeight: lineHeight,
    marginBottom: marginBottom,
  };
});

const Link = ({ text, large, href, fontCase, customColor, lineHeight, marginBottom, children, to }) => {
  return (
    <StyledLink
      to={to}
      href={href}
      large={large}
      fontCase={fontCase}
      customColor={customColor}
      lineHeight={lineHeight}
      marginBottom={marginBottom}
    >
      {text}
      {children}
    </StyledLink>
  );
};

export default Link;
