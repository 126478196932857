import React from 'react';
import { styled } from '@mui/material';
import { Box as MBox } from '@mui/material';

const StyledBox = styled(MBox)(({ theme, bgcolor, height }) => ({
  color: theme.palette[bgcolor]?.main || bgcolor,
  width: '100%',
  height: height,
  marginBottom: '10px',
  borderRadius: '10px',
  backgroundColor: 'white',
  boxShadow: '1px 2px 1px -1px rgba(0,0,0,0.2), 1px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
  padding: '10px',
}));

const Box = ({ bgcolor, height, children }) => {
  return (
    <StyledBox bgcolor={bgcolor} height={height}>
      {children}
    </StyledBox>
  );
};

export default Box;
