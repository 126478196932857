import React from 'react';
import { Container, Grid } from '@mui/material';
import '../index.css';
import { CategoriesButton } from '../components/categories-button';
import Title from '../components/typography';
import '../index.css';

const Categories = () => {
  return (
    <Container>
      <Grid id="explore" items xs={12} md={6}>
        <div className="explore-title">
          <Title text="Explore categories" size="h3" />
        </div>
        <Grid className="explore-container" container wrap="wrap" justifyContent="center">
          <div>
            <CategoriesButton text="Cybersecurity" />
          </div>

          <div>
            <CategoriesButton text="Mashine Learning and  AI" />
          </div>
          <div>
            <CategoriesButton text="Business Analytics" />
          </div>
          <div>
            <CategoriesButton text="IT Project Management" />
          </div>
          <div>
            <CategoriesButton text="Programming  " />
          </div>
        </Grid>
        {/* <div className="show-more-button">
          <ExpandMenuButton text="Load more" fullWidth={false} />
        </div> */}
      </Grid>
    </Container>
  );
};

export default Categories;
