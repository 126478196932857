import React from 'react';
import { styled } from '@mui/material';
import { Typography as MTypography } from '@mui/material';

const StyledTypography = styled(MTypography)(({
  sx,
  theme,
  fontSize,
  textTransformProp,
  marginBottom,
  paddingLeft,
  lineHeight,
  customColor,
}) => {
  const [colorKey, shade] = customColor ? customColor.split('.') : ['secondary', 'main'];

  const isValidKey = theme.palette[colorKey];

  const color = isValidKey ? theme.palette[colorKey][shade || 'main'] : theme.palette.secondary.main;

  return {
    color,
    ...sx,
    fontSize: fontSize,
    textTransform: textTransformProp === 'lowercase' ? 'lowercase' : 'initial',
    marginBottom: marginBottom,
    paddingLeft: paddingLeft,
    lineHeight: lineHeight,

    '&.Mui-checked': {
      color: theme.palette.secondary.main,
      fontSize: fontSize,

      // '&:hover': {
      //   color: theme.palette.primary.main,
      // },
    },
  };
});

/*const HighlightedText = styled('span')({
  color: '#E4751B',
});*/

const Text = ({
  sx,
  text,
  size,
  textTransformProp,
  marginBottom,
  lineHeight,
  marginLeft,
  customColor,
  marginRight,
  children,
  //highlightedText = '',
  margin,
  instructor,
  lastUpdated,
  fontSize,
  ...otherProps
}) => {
  const textString = String(text);

  return (
    <StyledTypography
      {...otherProps}
      sx={sx}
      text={text}
      fontSize={fontSize}
      variant={size}
      textTransformProp={textTransformProp}
      marginBottom={marginBottom}
      lineHeight={lineHeight}
      marginLeft={marginLeft}
      customColor={customColor}
      marginRight={marginRight}
      margin={margin}
    >
      <div dangerouslySetInnerHTML={{ __html: textString }}></div>
      {/* {textParts[1]} */}
      {instructor && <span>{instructor}</span>}
      {lastUpdated && <span>{lastUpdated}</span>}
      {children}
    </StyledTypography>
  );
};

export default Text;
