import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import DehazeRoundedIcon from '@mui/icons-material/DehazeRounded';
import { AppBar, Box, IconButton, ListItemText, useMediaQuery, List, ListItem, Drawer } from '@mui/material';
import { styled } from '@mui/material';
import { Toolbar as MToolbar } from '@mui/material';
import Select from '../select';
import Input from '../input';
import Text from '../text';
import Button, { SignupButton } from '../button';

import MenuItem from '../menu-item';
import globeIcon from '../../assets/globe.png';
import logoIcon from '../../assets/spotit-logo.png';
import accountIcon from '../../assets/account.png';
import searchIcon from '../../assets/search.png';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const StyledToolbar = styled(MToolbar)(({ theme }) => ({
  color: theme.palette,
  backgroundColor: 'transparent',
  fontCase: 'lowercase',
  '& *': {
    color: '#000000',
  },
  '&.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular': {
    justifyContent: 'space-between',
    paddingLeft: '10%',
    paddingRight: '10%',
    paddingTop: '20px',
    paddingBottom: '20px',
    boxShadow: '0 4px 6px 1px rgba(0, 0, 0, 0.1), 0 3px 4px 1px rgba(0, 0, 0, 0.06)',
  },
}));

const Navbar = ({ children, size }) => {
  return <StyledToolbar variant={size}>{children}</StyledToolbar>;
};

const MyAppBar = () => {
  const { t, i18n } = useTranslation();
  const { data } = useSelector((state) => state.data);
  const [language, setLanguage] = React.useState(i18n.language);
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const [searchField, setSearchField] = useState('');

  const filteredCourses =
    data &&
    data.filter((course) => {
      return course.title.toLowerCase().includes(searchField.toLowerCase());
    });

  const handleChange = (e) => {
    setSearchField(e.target.value);
  };

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;

    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <List>
        <ListItem button>
          <ListItemText primary="Home" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <AppBar position="fixed" style={{ background: '#fff', boxShadow: 'none', width: '100%', zIndex: 999 }}>
      <Navbar sx={{ justifyContent: 'space-between' }}>
        {isMobile ? (
          <>
            <IconButton edge="start" color="inherit" arial-label="menu" onClick={handleDrawerToggle}>
              <DehazeRoundedIcon />
            </IconButton>
            <IconButton edge="sart" color="inherit" arial-label="logo">
              <img src={logoIcon} alt="spotit-logo" style={{ width: '100%' }} />
            </IconButton>
            <IconButton edge="sart" color="inherit" arial-label="logo">
              <img src={accountIcon} alt="account-icon" style={{ width: '100%' }} />
            </IconButton>
            <Drawer anchor="left" open={mobileOpen} onClose={handleDrawerToggle}>
              {drawer}
            </Drawer>
          </>
        ) : (
          <div className="nav-items nav-items-guest">
            <a href="/">
              <img src={logoIcon} alt="spotit-logo" width={107} height={50} />
            </a>

            <a href="/#courses-list" className="global-a-hovers">
              <Text
                text={t('nav.explore_courses')}
                size="h5"
                sx={{
                  flexGrow: 1,
                  ':hover': {
                    color: '#003F91',
                  },
                }}
              ></Text>
            </a>
            <a
              href="https://docs.google.com/forms/d/1fTNTerYiQgumiI38AK3jQ5KRhhYrAVNBpGtUJZONx_E"
              target="_blank"
              rel="noreferrer"
              className="global-a-hovers"
            >
              <Text
                text={t('nav.submit_courses')}
                size="h5"
                sx={{
                  ':hover': {
                    color: '#003F91',
                  },
                }}
              ></Text>
            </a>

            <div>
              <Input
                placeholder={t('nav.search')}
                size="small"
                icon={searchIcon}
                style={{ width: '352px' }}
                onChange={handleChange}
              />
              {searchField !== '' && filteredCourses && filteredCourses.length > 0 && (
                <div className="search-results">
                  {filteredCourses.map((course) => {
                    return (
                      <a href={`/courses/${course.id}`} key={course.id}>
                        <Text text={course.title} size="h5" marginBottom={8} />
                      </a>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        )}

        {isLargeScreen && (
          <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '50px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '0' }}>
              <img src={globeIcon} alt="globe" style={{ width: '17px' }} />
              <Select value={language} onChange={handleLanguageChange} sx={{ mr: 2 }}>
                <MenuItem value="lv">LV</MenuItem>
                <MenuItem value="en">ENG</MenuItem>
              </Select>
            </Box>
            <div className="login-signup-fields">
              <Button text={t('nav.login')} href="/login" textColor="primary" small={true} fontSize={false} />
              <SignupButton text={t('nav.sign_up')} href="/sign-up" textColor="secondary" small={true} />
            </div>
          </Box>
        )}
      </Navbar>
    </AppBar>
  );
};

export default MyAppBar;
