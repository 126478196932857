import React, { useState } from 'react';
import { Grid, Container } from '@mui/material';
import Text from '../../text';

import { CatButton } from '../../categories-button';
import SubInput from '../subscribe-input';

import facebookIcon from 'assets/facebook-filled.png';
import footerImg from 'assets/newsletter-section.png';

import linkedinIcon from 'assets/linkedin-filled.png';
import logoImage from '../../../assets/logo-1.png';
import { useTranslation } from 'react-i18next';
import jsonp from 'jsonp';

const Footer = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();
    const url =
      'https://spotit.us17.list-manage.com/subscribe/post-json?u=58a9d4d26221e7561ac0b0e74&amp;id=88780d9c9d&amp;f_id=00ed77e0f0';
    jsonp(`${url}&EMAIL=${email}`, { param: 'c' }, () => {
      setEmail('');
    });
  };

  return (
    <>
      <Grid item xs={12} md={6}>
        <Grid container sx={{ position: 'relative', marginTop: '100px' }}>
          <img src={footerImg} alt="footer background" style={{ width: '100%', height: 'auto' }} />
          <div className="footer-newsletter-section">
            <Text text={t('footer.newsletter')} size="h2" customColor="secondary.light" marginBottom="40px" />
            <SubInput
              placeholder={t('footer.your_email_address')}
              value={email}
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              onClick={onSubmit}
            />
          </div>
        </Grid>
      </Grid>
      <Grid items sx={12} md={6}>
        <div className="footer-top-block">
          <div className="footer-top-text">
            <Text size="h3" text={t('common.connect_with')} highlightedText="it" fontWeight="600" />
            <CatButton
              text={t('buttons.submit_courses')}
              href="https://docs.google.com/forms/d/1fTNTerYiQgumiI38AK3jQ5KRhhYrAVNBpGtUJZONx_E"
              target="_blank"
              rel="noreferrer"
            />
          </div>
        </div>
      </Grid>
      <div className="footer-section">
        <Container>
          <Grid container sx={{ marginLeft: '20px' }}>
            <Grid items xs={12} md={4} style={{ paddingRight: '20px' }}>
              <img src={logoImage} alt="spotit" width={107} height={45} style={{ marginBottom: '5px' }} />
              <div className="spotit-text-section">
                <Text text={t('footer.spotit_text')} lineHeight="30px" customColor="secondary.light" />
              </div>
            </Grid>
            <Grid items xs={12} md={2} style={{ paddingRight: '20px' }}></Grid>
            <Grid
              items
              xs={12}
              md={2}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                paddingLeft: '50px',
                marginTop: '50px',
              }}
            >
              <div className="footer-home-section">
                <a href="/">
                  <Text text={t('footer.home')} customColor="secondary.light"></Text>
                </a>
                <a href="/#courses-list">
                  <Text text={t('footer.explore_courses')} customColor="secondary.light"></Text>
                </a>
              </div>
            </Grid>
            <Grid
              items
              xs={12}
              md={2}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                paddingLeft: '40px',
                marginTop: '63px',
              }}
            >
              <div className="footer-about-section">
                <a href="#" target="_blank" rel="noreferrer">
                  <Text text={t('footer.about_us')} customColor="secondary.light"></Text>
                </a>
                <a href="#" target="_blank" rel="noreferrer">
                  <Text text={t('footer.contact_us')} customColor="secondary.light"></Text>
                </a>
                <a href="#" target="_blank" rel="noreferrer">
                  <Text text={t('footer.help_center')} customColor="secondary.light"></Text>
                </a>
              </div>
            </Grid>
            <Grid
              items
              xs={12}
              md={2}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                paddingLeft: '40px',
                marginTop: '63px',
              }}
            >
              <div className="footer-policies-section">
                <a
                  // href="/privacy-policy"
                  href="https://docs.google.com/document/d/1h6IlbprzsjM2_FyMjEFXUg1nNaGvUBnDx6YSSy1toqc/edit?usp=sharing"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Text text={t('footer.privacy_policy')} size="h5" customColor="secondary.light"></Text>
                </a>
                <a
                  href="https://docs.google.com/document/d/1ItB7UA-mwmrb_-vfgM4GR6tbEzND04WYVeYwSAEljv0/edit?usp=sharing"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Text text={t('footer.cookies_policy')} customColor="secondary.light"></Text>
                </a>
                <a
                  href="https://docs.google.com/document/d/1wANEOvSbvFUqYOLcEr8qBs3T6woINBxD7VhvW_XGYL4/edit"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Text text={t('footer.terms_and_conditions')} customColor="secondary.light"></Text>
                </a>
              </div>
            </Grid>
          </Grid>
          <Grid container sx={{ marginLeft: '20px' }}>
            <div className="social-icons">
              <a href="#" target="_blank" rel="noreferrer">
                <img src={facebookIcon} alt="facebook-icon" width={24} height={24} style={{ marginRight: '30px' }} />
              </a>
              <a href="https://www.linkedin.com/company/spotitpro" target="_blank" rel="noreferrer">
                <img src={linkedinIcon} alt="instagram-icon" width={24} height={24} />
              </a>
            </div>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default Footer;
