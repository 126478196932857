import React from 'react';
import { styled } from '@mui/system';
import { Button as CButton } from '@mui/material';

const StyledButton = styled(CButton)(({ theme, textTransformProp, fullWidth, size }) => ({
  color: theme.palette.primary.main,
  borderRadius: 10,
  width: fullWidth ? '100%' : '200px',
  height: '75px',
  padding: '10px 20px',
  fontSize: size ? '24px' : '16px',
  border: `2px solid ${theme.palette.primary.main}`,
  boxShadow: 'none',
  backgroundColor: theme.palette.secondary.light,
  textTransform: textTransformProp === 'lowercase' ? 'lowercase' : 'initial',

  '&:hover': {
    backgroundColor: theme.palette.common.light,
    border: 'none',
    boxShadow: 'none',
    color: `${theme.palette.secondary.dark}`,
  },
}));

const ExpandButton = styled(CButton)(({ theme, noLeftRadius, fullWidth, textTransformProp, fontSize }) => ({
  color: theme.palette.secondary.main,
  backgroundColor: theme.palette.common.light,
  border: `2px solid ${theme.palette.common.light}`,
  borderTopLeftRadius: noLeftRadius ? 0 : 10,
  borderBottomLeftRadius: noLeftRadius ? 0 : 10,
  borderTopRightRadius: '10px',
  borderBottomRightRadius: '10px',
  width: fullWidth ? '100%' : '200px',

  height: '75px',
  padding: '10px 20px',
  fontSize: fontSize || '16px',
  boxShadow: 'none',

  textTransform: textTransformProp === 'lowercase' ? 'lowercase' : 'initial',

  '&:hover': {
    backgroundColor: theme.palette.common.white,
    border: `2px solid ${theme.palette.common.light}`,
    color: `${theme.palette.secondary.dark}`,
    boxShadow: 'none',
  },
}));

const StyledCategoriesButton = styled(CButton)(({ theme, textTransformProp, fontSize }) => ({
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.secondary.light,
  border: `2px solid ${theme.palette.primary.light}`,
  fontColor: theme.palette.secondary.main,
  height: '75px',
  padding: '10px 20px',
  borderRadius: '10px',
  fontSize: fontSize || theme.typography.h5.fontSize,

  textTransform: textTransformProp === 'lowercase' ? 'lowercase' : 'initial',
  boxShadow: 'none',

  '&:hover': {
    backgroundColor: theme.palette.common.light,
    border: `2px solid ${theme.palette.common.light}`,
    color: `${theme.palette.secondary.dark}`,
    boxShadow: 'none',
  },
}));

const CategoriesButton = ({ text, fontSize, fontColor, href }) => {
  return (
    <StyledCategoriesButton variant="contained" fontColor={fontColor} fontSize={fontSize} href={href}>
      {text}
    </StyledCategoriesButton>
  );
};

const CatButton = ({ text, textColor, textTransformProp = 'initial', href, target, rel }) => {
  return (
    <StyledButton
      variant="contained"
      textTransformProp={textTransformProp}
      textColor={textColor}
      href={href}
      target={target}
      rel={rel}
    >
      {text}
    </StyledButton>
  );
};

const ExpandMenuButton = ({
  text,
  noLeftRadius,
  height,
  fullWidth,
  onClick,
  type,
  fontSize,
  textTransformProp = 'initial',
}) => {
  return (
    <ExpandButton
      variant="contained"
      noLeftRadius={noLeftRadius}
      height={height}
      fullWidth={fullWidth}
      onClick={onClick}
      type={type}
      fontSize={fontSize}
      textTransformProp={textTransformProp}
    >
      {text}
    </ExpandButton>
  );
};

export { CatButton, ExpandMenuButton, CategoriesButton };
