import React from 'react';
import { styled } from '@mui/material';
import { Card as MCard, CardMedia } from '@mui/material';

const StyledCard = styled(MCard)(({ theme }) => ({
  color: theme.palette.secondary.main,
  // padding: '30px',
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '40px',

  '.image-container': {
    width: '100%',
    height: '66.66%',
    overflow: 'hidden',
    marginBottom: '40px',
  },

  '.card-image': {
    width: '100%',
    height: 'auto',
  },
}));

const Card = ({ children, imageUrl }) => {
  return (
    <StyledCard>
      {imageUrl && (
        <div className="image-container">
          <CardMedia
            component="img"
            image={imageUrl}
            alt="Course Image"
            sx={{ objectFit: 'cover', width: '100%', height: '100%' }}
          />
        </div>
      )}

      <div className="content-container">{children}</div>
    </StyledCard>
  );
};

export default Card;
