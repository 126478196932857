import React from 'react';
import { useRouteError } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import Text from '../components/text';
import { CategoriesButton } from '../components/categories-button';

import errorImage from '../assets/error.png';

import { useTranslation } from 'react-i18next';

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Grid item xs={12} md={6}>
      <div className="error">
        <div style={{ marginBottom: '60px' }}>
          <img src={errorImage} width="169px" height="148px"></img>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Text text={t('error_page.text')} size="h2" marginBottom="20px" />
          <Text text={t('error_page.text_2')} size="h3" marginBottom="50px" />
        </div>
        <CategoriesButton onClick={() => navigate('/')} text={t('buttons.back_home')} width={false} />
      </div>
    </Grid>
  );
}
