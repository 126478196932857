import React from 'react';
import { Grid } from '@mui/material';
import Text from '../../text';
import '../../../index.css';

import clockImage from '../../../assets/clock.png';
import dateImage from '../../../assets/date.png';
import brainImage from '../../../assets/brain.png';
import lockImage from '../../../assets/lock.png';
import mortarboardImage from '../../../assets/mortarboard.png';
import globeImage from '../../../assets/globe.png';
import priceImage from '../../../assets/price.png';

const SideMenu = ({ course }) => {
  return (
    <Grid>
      <div className="side-menu">
        <div className="side-menu-item">
          <img src={clockImage} alt="clock-img" width="23px" height="23px" />
          <Text text={course.duration} size="h5" />
        </div>
        <div className="horizontal-line"></div>

        <div className="side-menu-item">
          <img src={dateImage} alt="" width="22px" height="22px" />
          <Text text={course.date} size="h5" />
        </div>
        <div className="horizontal-line"></div>

        <div className="side-menu-item">
          <img src={brainImage} alt="" width="21px" height="21px" />
          <Text text={course.level} size="h5" />
        </div>
        <div className="horizontal-line"></div>

        <div className="side-menu-item">
          <img src={lockImage} alt="" />
          <Text text={course.format} size="h5" />
        </div>
        <div className="horizontal-line"></div>

        <div className="side-menu-item">
          <img src={mortarboardImage} alt="" width="22px" height="18px" />
          <Text text={course.students} size="h5" />
        </div>
        <div className="horizontal-line"></div>

        <div className="side-menu-item">
          <img src={globeImage} alt="" width="23px" height="23px" />
          <Text text={course.language} size="h5" marginLeft="8px" />
        </div>
        <div className="horizontal-line"></div>
        <div className="side-menu-item">
          <img src={priceImage} alt="" width="22px" height="22px" />
          <Text text={course.price} size="h5" marginLeft="8px" />
        </div>
      </div>
    </Grid>
  );
};

export default SideMenu;
