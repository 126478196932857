import React from 'react';
import { InputAdornment, styled } from '@mui/material';
import { TextField as MInput } from '@mui/material';

const StyledInput = styled(MInput)(({ theme, height, background, marginBottom }) => ({
  '& .MuiInputLabel-root': {
    color: theme.palette.secondary.main,
  },
  '& .MuiInputBase-root': {
    borderRadius: '10px',
    backgroundColor: background || 'transparent',
    height: height || 'auto',
    border: 'none',
    marginBottom: marginBottom || '0',
    '&::before': {
      borderBottom: '40px',
    },
  },
  '& .MuiInputAdornment-root img': {
    width: 15,
    height: 15,
  },
}));

const Input = ({ size, icon, width, height, background, marginBottom, ...props }) => {
  return (
    <StyledInput
      {...props}
      width={width}
      height={height}
      background={background}
      marginBottom={marginBottom}
      type="text"
      size={size}
      InputProps={{
        startAdornment: icon ? (
          <InputAdornment position="start">
            <img src={icon} alt="Icon" />
          </InputAdornment>
        ) : null,
      }}
    />
  );
};

export default Input;
