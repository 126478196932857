import React from 'react';
import { styled } from '@mui/material';
import { Select as MSelect, InputLabel } from '@mui/material';

const StyledSelect = styled(MSelect)(({ theme }) => ({
  color: theme.palette.secondary.main,

  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },

  '& .MuiSelect-root .MuiSelect-icon': {
    color: '#000000',
  },
}));

const Select = ({ value, children, text, ...props }) => {
  return (
    <>
      <InputLabel>
        {text}
        <StyledSelect {...props} value={value}>
          {children}
        </StyledSelect>
      </InputLabel>
    </>
  );
};

export default Select;
