import React, { useEffect } from 'react';
import { Container, Grid, Box } from '@mui/material';
import Page from '../components/page';
import Link from '../components/link';
import Text from '../components/text';
import SideMenu from '../components/landing-components/side-menu';
import { ExpandMenuButton } from '../components/categories-button';
import CourseBenefits from '../components/courses-page/course-benefits';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { addFavorite, removeFavorite, fetchFavorites } from '../firebase';
import { getUserFromFirebase } from '../firebase';
import { setUser } from '../features/user/userSlice';
import { useNavigate } from 'react-router-dom';

import BookmarkBorderIcon from 'assets/favourite-icon.png';
import BookmarkIcon from 'assets/favourite-icon-filled.png';
import ratingImage from 'assets/rating.png';

const CoursesPage = () => {
  const { id } = useParams();
  const { data } = useSelector((state) => state.data);
  const course = data.find((c) => c.id === id);

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const favorites = user ? user.favorites || [] : [];
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.id) {
      dispatch(fetchFavorites(user.id));
    }
  }, [dispatch, user?.id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!course) {
    return <div>Course not found</div>;
  }

  const handleAddFavorites = async (courseId) => {
    if (!user) {
      navigate('/login');
      return;
    }
    try {
      await addFavorite(user.id, courseId);
      const firebaseUser = await getUserFromFirebase(user.id);
      if (firebaseUser) {
        dispatch(setUser(firebaseUser));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemoveFavorites = async (courseId) => {
    try {
      if (!user) {
        navigate('/login');
        return;
      }
      await removeFavorite(user.id, courseId);
      const firebaseUser = await getUserFromFirebase(user.id);
      if (firebaseUser) {
        dispatch(setUser(firebaseUser));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const isFavorited = Array.isArray(favorites) && favorites.includes(course.id);

  return (
    <Page>
      <Container maxWidth="lg">
        <Grid item xs={12} md={6}></Grid>
        <Grid container style={{ marginTop: '30px', padding: '0', marginLeft: '0', marginRight: '0' }}>
          <Grid items xs={12} md={4}>
            <div style={{ display: 'flex', maxWidth: '400px' }}>
              <Text text={course.title || ''} size="h2" lineHeight="45px" style={{ width: '100%' }} />
              {isFavorited ? (
                <img
                  src={BookmarkIcon}
                  alt="Bookmark Icon"
                  width="30px"
                  height="35px"
                  style={{ paddingRight: '0px', cursor: 'pointer' }}
                  onClick={() => {
                    handleRemoveFavorites(course.id);
                  }}
                />
              ) : (
                <img
                  src={BookmarkBorderIcon}
                  alt="Bookmark Border Icon"
                  width="30px"
                  height="35px"
                  style={{ paddingRight: '0px', cursor: 'pointer' }}
                  onClick={() => {
                    handleAddFavorites(course.id);
                  }}
                />
              )}
            </div>
            <div style={{ marginTop: '35px' }}>
              <Link customColor="primary.light">
                <div style={{ marginBottom: '15px' }}>{course.instructor}</div>
              </Link>
              <Text text={course.lastUpdated || ''} size="h5" customColor="secondary.contrastText" />
              <div style={{ marginTop: '15px', display: 'flex' }}>
                <img src={ratingImage} height="24px" />
                <Text text="324 Reviews" marginLeft="15px" />
              </div>
            </div>
            <Box backgroundColor="#EBF0F5" borderRadius="10px" marginTop="35px" width="400px" height="590px">
              <div className="courses-page-side-menu">
                <SideMenu course={course} />
                <div style={{ marginTop: '20px', display: 'flex' }}>
                  <ExpandMenuButton text="Go to course" fullWidth />
                </div>
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} md={8} style={{ paddingTop: '0' }}>
            <div style={{ width: '100%', marginBottom: '50px' }}>
              <img
                src={course.courseImageUrl}
                style={{ width: '100%', height: 'auto', maxWidth: '100%', maxHeight: '100%' }}
              />
            </div>

            <Text text="Overview" size="h3" marginBottom="20px" />
            <Text text={course.description || ''} size="h5" lineHeight="30px" />
            <div style={{ marginTop: '45px' }}>
              <Text text="You Will Learn How To" size="h3" lineHeight="45px" />
            </div>
            <Box
              backgroundColor="#EBF0F5"
              borderRadius="10px"
              width="100%"
              marginTop="20px"
              padding="40px"
              overflow="auto"
              boxSizing="border-box"
            >
              <CourseBenefits benefits={course.benefits} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default CoursesPage;
