import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  favorites: [],
};

export const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.favorites = action.payload.favorites || [];
    },
    logoutUser: () => initialState,
    updateUserFavorites: (state, action) => {
      state.favorites = action.payload.favorites;
    },
  },
});

export const { setUser, logoutUser, updateUserFavorites } = userSlice.actions;

export default userSlice.reducer;
